import { createGlobalStyle } from 'styled-components';
import dark from './themes/dark';
import * as t from './tokens';
import { themeable } from 'themes/utils';

export const GlobalStyle = createGlobalStyle`
    ${dark}

    :root {
        ${t.headlineSizeL.name}: 24px;
        ${t.containerMaxWidth.name}: 1600px;
    }

    body {
        color: ${themeable('textColor')};
        background: ${themeable('bodyBackgroundColor')};
    }
`;
