import { useAuth } from '../../context/Auth';
import withContainer from '../../components/Container/withContainer';
import { getStudents } from '../../api/students/students';
import { StudentInfo } from '../../api/students/students.types';
import { ColumnProps } from '../../ui/Table';
import { PowerTable } from 'ui/PowerTable';
import { Headline } from 'ui/Styled/Styled';
import Skeleton from "../../components/Skeleton/Skeleton";

type StudentsRowProps = Pick<
    StudentInfo,
    'image' | 'fullName' | 'phone' | 'birthday' | 'email'
>;

const STUDENTS_COLUMNS: ColumnProps<StudentsRowProps> = {
    image: '',
    fullName: 'ФИО',
    phone: 'Номер телефона',
    birthday: 'Дата рождения',
    email: 'Почта'
};

const StudentsList = () => {
    const auth = useAuth();

    if (!auth) {
        return <Skeleton />;
    }

    return (
        <>
            <Headline>Студенты</Headline>
            <PowerTable
                columns={STUDENTS_COLUMNS}
                getter={getStudents}
                noRowsMsg="Студенты не найдены"
            />
        </>
    );
};

export default withContainer(StudentsList);
