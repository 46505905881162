import {
    SkeletonWrapper,
    SkeletonCell
} from './Skeleton.styled';
  
const Skeleton = () => (
    <SkeletonWrapper>      
        { Array(6).fill(0).map((_, index) => ( // Увеличиваем до 6
            <SkeletonCell key={ index } style={{ width: '100%', height: '90px', marginBottom: '8px' }} />
        )) }
    </SkeletonWrapper>
);
  
export default Skeleton;
  
