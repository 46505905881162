import { FC } from 'react';
import * as S from './Popup.styled';
import { Portal } from 'components/Portal/Portal';

type PopupProps = ChildrenProps &
    OpenProps & {
        onClose: () => void;
    };

export const Popup: FC<PopupProps> = ({ children, isOpen, onClose }) => {
    return (
        <Portal>
            {isOpen && <S.MainBlurStyle />}

            <S.Wrapper $isOpen={isOpen}>
                <S.Overlay onClick={onClose} />
                <S.Container>{children}</S.Container>
            </S.Wrapper>
        </Portal>
    );
};
