import styled, { css } from 'styled-components';
import { headlineSizeL } from '../../tokens';
import { themeable } from 'themes/utils';

export const Headline = styled.h1`
    font-size: ${headlineSizeL};
    color: ${themeable('textColor')};
    font-weight: 400;
    margin-bottom: 24px;
    text-align: center;
`;
export const Title = styled.p`
    color: rgb(255, 255, 255);
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
`;
export const absoluteMixin = css`
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
`;

export const RelativeContainer = styled.div`
    position: relative;
`;
