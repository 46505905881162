export const RemoveIcon = () => (
    <svg
        width="17.718750"
        height="17.716797"
        viewBox="0 0 17.7188 17.7168"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M0.9 0.9L8.85 8.85L16.81 16.81M8.85 8.85L16.81 0.9M8.85 8.85L0.9 16.81"
            stroke="#718EBF"
            strokeOpacity="1.000000"
            strokeWidth="1.800000"
            strokeLinejoin="round"
            strokeLinecap="round"
        />
    </svg>
);
