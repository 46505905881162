import React, {
    createContext,
    FC,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import { useLogined } from '../Auth';
import { UserInfo } from '../../api/users/users.types';
import { getCurrentUser } from '../../api/users/users';

type AuthContextProps = {
    user?: UserInfo;
    refresh(): Promise<boolean>;
};

type AuthProviderProps = {
    children: React.ReactNode;
};

export const UserContext = createContext<AuthContextProps>({
    refresh: () => Promise.resolve(false)
});

export const UserProvider: FC<AuthProviderProps> = ({ children }) => {
    const logined = useLogined();
    const [user, setUser] = useState<UserInfo>();
    const checked = useRef(false);

    const refresh = async () => {
        try {
            const user = await getCurrentUser();
            setUser(user);
            checked.current = true;
            return true;
        } catch {
            checked.current = false;
            return false;
        }
    };

    useEffect(() => {
        if (logined && !checked.current) {
            refresh();
        }
    }, [logined, checked]);

    const providerProps = useMemo(() => ({ user, refresh }), [user]);

    return (
        <UserContext.Provider value={providerProps}>
            {children}
        </UserContext.Provider>
    );
};
