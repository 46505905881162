import React, { FC, useCallback, useContext } from 'react';
import { Headline } from '../../ui/Styled/Styled';
import { Form } from 'react-final-form';
import Button from '../../ui/Button/Button';
import FormContainer from '../../ui/FormContainer/FormContainer';
import { RouterPaths } from 'helpers/router-paths';
import { AuthContext } from '../../context/Auth';
import { signupOrg } from '../../api/auth/auth';
import { useNavigate } from 'react-router-dom';
import withContainer from '../../components/Container/withContainer';
import FormField from '../../ui/FormField/FormField';
import { RegisterOrgFormData } from '../../api/auth/auth.types';

const Field = FormField<RegisterOrgFormData>;

const RegisterOrgPage: FC = () => {
    const { refresh } = useContext(AuthContext);
    const navigate = useNavigate();

    const onSubmit = useCallback(
        (props: RegisterOrgFormData) => {
            signupOrg(props)(async () => {
                const authorized = await refresh();
                if (authorized) {
                    navigate(RouterPaths.Home);
                }
            });
        },
        [refresh, navigate]
    );

    return (
        <>
            <Headline>Регистрация организации</Headline>
            <Form onSubmit={onSubmit}>
                {({ handleSubmit, submitting }) => (
                    <FormContainer onSubmit={handleSubmit}>
                        <Field
                            name="organization.title"
                            type="string"
                            required
                            label="Название организации"
                            placeholder='ООО "Курсовед"'
                        />
                        <Field
                            name="organization.phone"
                            type="tel"
                            required
                            label="Контактный телефон организации"
                            placeholder="+79999999999"
                        />
                        <Field
                            name="organization.email"
                            type="email"
                            required
                            label="E-mail организации"
                            placeholder="example@kursoved.ru"
                        />
                        <Field
                            name="organization.inn"
                            type="number"
                            required
                            label="ИНН"
                            placeholder="ИНН"
                        />
                        <Field
                            name="organization.postAddress"
                            type="string"
                            required
                            label="Почтовый адрес"
                            placeholder="ул. Ленина, д. 10, кв. 15 г. Москва Российская Федерация 101000"
                        />
                        <Field
                            name="organization.postIndex"
                            type="number"
                            required
                            label="Почтовый индекс"
                            placeholder="101000"
                        />
                        <Field
                            name="organization.bankAccountNumber"
                            type="number"
                            maxLength={20}
                            minLength={20}
                            required
                            label="Номер банковского счета (для выставления счетов)"
                            placeholder="40702810200210000237"
                        />
                        <Headline>Контактное лицо организации:</Headline>
                        <Field
                            name="operator.surname"
                            type="string"
                            required
                            label="Фамилия"
                            placeholder="Иванов"
                        />
                        <Field
                            name="operator.name"
                            type="string"
                            required
                            label="Имя"
                            placeholder="Иван"
                        />
                        <Field
                            name="operator.lastName"
                            type="text"
                            required
                            label="Отчество"
                            placeholder="Иванов"
                        />
                        <Field
                            name="operator.phone"
                            type="tel"
                            required
                            label="Телефон"
                            placeholder="+79999999999"
                        />
                        <Field
                            name="operator.email"
                            type="email"
                            required
                            label="Email"
                            placeholder="example@kursoved.ru"
                        />
                        <Headline>Данные для авторизации</Headline>
                        <Field
                            name="operator.password"
                            type="password"
                            required
                            label="Пароль"
                        />
                        <Field
                            name="operator.confirmPassword"
                            type="password"
                            required
                            label="Повторите пароль"
                        />
                        <Button type="submit" disabled={submitting}>
                            Создать
                        </Button>
                    </FormContainer>
                )}
            </Form>
        </>
    );
};

export default withContainer(RegisterOrgPage);
