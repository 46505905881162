import { OrganizationId } from 'api/organizations/organizations.types';
import { get, postWithToast } from '../../utils/fetch';
import * as Types from './courses.types';

export const createCourse = (props: Types.CreateCourseFormData) =>
    postWithToast('Course/CreateCourse', props);

export const getCourses = (
    search?: string,
    organizationId: OrganizationId = 'current'
) =>
    get<Types.CoursesResponse>(`Course/GetCoursesByOrgId/${organizationId}`, {
        search
    });

export const assignCourse = (props: Types.AssignCourseFormData) =>
    postWithToast('AssignCourse/AddAssignCourse', props);

export const getCourseDateEndLearning = (courseId: number, startDate: string) =>
    get('AssignCourse/GetDateEndLearning', { courseId, startDate });
