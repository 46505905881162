import { RadioGroupProps } from './RadioGroup.types';
import * as S from './RadioGroup.styled';

export function RadioGroup<T>({
    options,
    value,
    onChange,
    name
}: RadioGroupProps<T>) {
    if (!options.length) {
        return null;
    }

    return (
        <S.Container>
            {options.map((option) => (
                <label key={(option.value as string) || option.label}>
                    <S.Input
                        type="radio"
                        name={name}
                        checked={value === option.value}
                        onChange={(event) =>
                            onChange({
                                ...event,
                                target: { value: option.value }
                            })
                        }
                    />
                    <S.RadioItem $active={value === option.value}>
                        {option.label || (option.value as string)}
                    </S.RadioItem>
                </label>
            ))}
        </S.Container>
    );
}
