import { useAuth } from '../../context/Auth';
import withContainer from '../../components/Container/withContainer';
import { Headline } from '../../ui/Styled/Styled';
import RawFormField from 'ui/FormField/FormField';
import { CreateCommitteeFormData } from 'api/committies/committies.types';
import { Form } from 'react-final-form';
import { makeCreateFormFunc } from 'utils/api';
import { createCommittees } from 'api/committies/committies';
import FormContainer from 'ui/FormContainer/FormContainer';
import Button from 'ui/Button/Button';
import { FieldArray } from 'react-final-form-arrays';
import { AddComissionMember } from 'components/AddComissionMember';
import arrayMutators from 'final-form-arrays';
import Skeleton from 'components/Skeleton/Skeleton';
import ButtonContainer from 'ui/Button/ButtonContainer';

const FormField = RawFormField<CreateCommitteeFormData>;

const handleFormSubmit = makeCreateFormFunc(
    createCommittees,
    'Комиссия создана!'
);

const CreateComission = () => {
    const auth = useAuth();

    if (!auth) {
        return <Skeleton />;    }
    return (
        <>
            <Headline>Создание комиссии</Headline>

            <Form onSubmit={ handleFormSubmit } mutators={{ ...arrayMutators }}>
                { ({ handleSubmit, submitting }) => (
                    <FormContainer onSubmit={ handleSubmit }>
                        <FormField
                            name="title"
                            label="Название комиссии"
                            required
                        />
                        <Headline>Участники комиссии</Headline>
                        <FieldArray
                            render={ AddComissionMember }
                            name="members"
                        />
                        <ButtonContainer>
                            <Button type="submit" disabled={ submitting }>
                                Создать комиссию
                            </Button>
                        </ButtonContainer>
                    </FormContainer>
                ) }
            </Form>
        </>
    );
};

export default withContainer(CreateComission);
