import styled from 'styled-components';

export const RemoveButton = styled.div`
    cursor: pointer;
    width: 22.5px;
    height: 22.5px;
    position: absolute;
    left: 100%;
    margin-left: 20px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover svg * {
        stroke: rgba(255, 0, 0, 0.75);
    }
`;
