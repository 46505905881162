import styled from 'styled-components';
import { themeable } from 'themes/utils';

export const Wrapper = styled.div`
    width: 100%;
`;

// Styled контейнер для всего списка
export const Contaner = styled.table`
    width: max-content;
    min-width: 100%;
    flex: 1;
    display: block;
    box-sizing: border-box;
    padding-bottom: 43px;
`;

export const Body = styled.tbody`
    width: 100%;
    display: block;
`;

// Styled элемент списка
export const Row = styled.tr`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${themeable('secondaryBackground')};
    border-radius: 8px;
    min-width: 200px;
    min-height: 90px;
    box-sizing: border-box; /* Учитываем padding при расчете ширины */
    cursor: pointer;
    margin-top: 16px;
`;

// Styled контейнер для столбцов
export const Cell = styled.td`
    display: flex;
    justify-content: center; /* Горизонтальное центрирование */
    align-items: center; /* Вертикальное центрирование */
    flex: 1;
    text-align: center; /* Выравнивание текста по центру */
    min-width: 200px;

    padding: 16px;
`;

// Styled контейнер для заголовков
export const Head = styled.thead`
    display: block;
    margin: 0 0 20px;
    position: sticky;
    top: 0;
`;

export const BeforeHeaderCell = styled.th`
    width: 100%;
`;

export const HeadRow = styled.tr`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    min-width: 200px;
    box-sizing: border-box;
`;

// Styled элемент заголовка колонки
export const HeadingCell = styled.th`
    font-weight: bold;
    color: ${themeable('textColor')};
    padding: 0 8px;
    flex: 1;
    min-width: 200px;
    text-align: center;
    display: block;
`;
