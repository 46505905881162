export const RouterPaths = {
    Auth: '/auth',
    RegisterUser: '/signup',
    RegisterOrg: '/reg',
    Home: '/',
    Students: '/students', // чё-то походу не нужно это
    FindStudents: '/students/find',
    CreateStudents: '/students/add',
    FindOrganization: '/orgs/find',
    CreateOrganization: '/orgs/add',

    CreateCourse: '/courses/create',
    FindCourse: '/courses/find',
    AssignCourse: '/courses/assign',

    FindComission: '/comissions/find',
    CreateComission: '/comissions/add',
    ComissionPage: (id?: string) => `/comissions/${id ?? ':id'}`,
    StudentsDocuments: '/students-documents',
    Settings: '/settings',
    Profile: '/profile',
    SetPassword: '/password',
    Operators: '/operators'
};
