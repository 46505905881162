import styled, { keyframes } from 'styled-components';

const gradientFlow = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

export const SkeletonWrapper = styled.div`
  width: 100%;
  padding: 20px;
`;

export const SkeletonTableHeader = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 10px;
`;

export const SkeletonHeaderCell = styled.div`
  height: 20px;
  width: 100px;
  background: linear-gradient(45deg, rgb(63, 65, 74), rgb(29, 26, 43), rgb(63, 65, 74));
  background-size: 500% 500%;
  animation: ${gradientFlow} 5s infinite linear;
  border-radius: 8px;
`;

export const SkeletonRow = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 8px;
`;

export const SkeletonCell = styled.div`
  height: 20px;
  width: 100px;
  background: linear-gradient(45deg, rgb(63, 65, 74), rgb(29, 26, 43), rgb(63, 65, 74));
  background-size: 500% 500%;
  animation: ${gradientFlow} 5s infinite linear;
  border-radius: 8px;
`;
