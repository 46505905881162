import { useAuth } from '../../context/Auth';
import withContainer from '../../components/Container/withContainer';
import Tabs from '../../ui/Tabs/Tabs';
import { getAllStudents } from '../../api/students/students';
import { getAllOrganizations } from '../../api/organizations/organizations';
import { StudentInfo } from '../../api/students/students.types';
import { OrganizationInfo } from '../../api/organizations/organizations.types';
import { ColumnProps } from '../../ui/Table';
import { PowerTable } from '../../ui/PowerTable';

type StudentRowProps = Pick<StudentInfo, 'fullName' | 'organizationTitle'>;

const STUDENTS_COLUMNS: ColumnProps<StudentRowProps> = {
    fullName: 'ФИО',
    organizationTitle: 'Организация'
};

type OrganizationRowProps = Pick<OrganizationInfo, 'title' | 'inn'>;

const ORGANIZATIONS_COLUMNS: ColumnProps<OrganizationRowProps> = {
    title: 'Название организации',
    inn: 'ИНН'
};

const HomePage = () => {
    const auth = useAuth();

    if (!auth) {
        return <div>skeleton</div>;
    }

    return (
        <Tabs
            tabs={[
                {
                    label: 'Студенты',
                    content: (
                        <PowerTable
                            columns={STUDENTS_COLUMNS}
                            getter={getAllStudents}
                            noRowsMsg="Студенты не найдены"
                        />
                    )
                },
                {
                    label: 'Организации',
                    content: (
                        <PowerTable
                            columns={ORGANIZATIONS_COLUMNS}
                            getter={getAllOrganizations}
                            noRowsMsg="Организации не найдены"
                        />
                    )
                }
            ]}
        />
    );
};

export default withContainer(HomePage, { disablePaddingTop: true });
