/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormApi } from 'final-form';
import { FetchError, FetchResult } from '../typings/fetch';
import { FetchCallback } from './fetch';
import { toast } from 'react-toastify';

export function makeApiFunc<
    ReturnModel = any,
    RequestModel = any,
    ErrorReturn = any,
    FuncArgs = any,
    Func = (
        ...args: FuncArgs[]
    ) => FetchCallback<ReturnModel, RequestModel, ErrorReturn>
>(
    func: Func,
    onSuccess?: (response: FetchResult<ReturnModel>) => any,
    onError?: (res: FetchError<RequestModel>) => ErrorReturn
) {
    return (...args: FuncArgs[]): Promise<ErrorReturn> => {
        // @ts-expect-error func
        return func(...args)(onSuccess, onError);
    };
}

export function makeCreateFormFunc<RequestModel>(
    func: (data: RequestModel) => FetchCallback,
    successMessage = 'Успех!'
) {
    return (data: RequestModel, form: FormApi<RequestModel>) =>
        func(data)(() => {
            form.restart();
            toast.success(successMessage);
        });
}
