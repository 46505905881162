import { getTranslateGroup, TranslateKey, Translates } from '../messages';

import { OptionProps } from '../ui/Select/Select.types';

export function generateOptions<Key extends TranslateKey>(
    key: Key
): OptionProps<keyof Translates[Key]>[] {
    return Object.entries(getTranslateGroup(key)).map(([key, value]) => ({
        label: value,
        value: key as keyof Translates[Key]
    }));
}
