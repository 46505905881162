import { CommitteeMemberType } from 'api/committies/committies.types';
import {
    EducationType,
    AssignedQualificationType,
    IssuedDocument
} from '../api/courses/courses.types';
import { ResponseCodes } from '../helpers/constants';

export const messages: Partial<Record<ResponseCodes, string>> = {
    Error: 'Произошла ошибка, повторите попытку позже',
    Ok: 'Успешно!',
    AuthException: 'Неверный логин или пароль',
    Unauthorized: 'Вы не авторизованы',
    Invalid: 'Проверьте правильность заполнения полей',
    EmailAlreadyUse: 'Этот E-mail уже используется другим пользователем.'
};

const educationTypes: Record<EducationType, string> = {
    ProfessionalTraining: 'Профессиональное образование (ПО)',
    AdvancedTraining: 'Повышение квалификации (ПК)',
    ProfessionalRetraining: 'Профессиональная переподготовка (ПП)'
};

const qualificationTypes: Record<AssignedQualificationType, string> = {
    MayBeAdmitted: 'Может быть допущен',
    PersonnelCategory: 'Категория персонала',
    Rank: 'Разряд'
};

const issuedDocuments: Record<IssuedDocument, string> = {
    Diplom: 'Диплом',
    Sertificate: 'Свидетельство',
    TicketB: 'Удостоверение Б',
    TicketM: 'Удостоверение М',
    Protocol: 'Протокол'
};

const committeeMemberType: Record<CommitteeMemberType, string> = {
    Chairman: 'Председатель',
    SubChairman: 'Заместитель председателя',
    MemberCommittee: 'Член комиссии'
};

export default {
    messages,
    educationTypes,
    qualificationTypes,
    issuedDocuments,
    committeeMemberType
};
