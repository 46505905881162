import { Field } from 'react-final-form';
import { RadioGroup } from './RadioGroup';
import { RadioGroupFormFieldProps } from './RadioGroup.types';
import { useValidators } from 'hooks/useValidators';
import { SubmissionError } from 'typings/fetch';
import { Meta } from 'ui/Input/Input.styled';

export function RadioGroupField<FormData, OptionValue = unknown>({
    name,
    validators: rawValidators,
    ...radioGroupProps
}: RadioGroupFormFieldProps<FormData, OptionValue>) {
    const validators = useValidators(rawValidators, { ...radioGroupProps });

    return (
        <Field<OptionValue>
            validate={validators}
            name={name}
            render={({ input, meta }) => (
                <>
                    <RadioGroup<OptionValue> {...input} {...radioGroupProps} />

                    {meta.touched &&
                        (meta.error ||
                            (meta.submitError &&
                                !meta.dirtySinceLastSubmit)) && (
                            <Meta>
                                {meta.error ||
                                    meta.submitError.map(
                                        ({
                                            code,
                                            message
                                        }: SubmissionError) => (
                                            <div key={code}>{message}</div>
                                        )
                                    )}
                            </Meta>
                        )}
                </>
            )}
        />
    );
}
