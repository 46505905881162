import { css } from 'styled-components';
import { mainBackgroundColor } from '../tokens';
import { Theme } from 'context/Theme/Theme.context';

const secondaryBackground = '#2d303e';

type ThemeParam = string | undefined;

export const darkTheme = {
    name: 'dark' as Theme,
    bodyBackgroundColor: '#393C49',
    textColor: '#fff',
    mainBackgroundColor: '#252836',
    secondaryBackground,

    sidebar: {
        menuLabelColor: '#718ebf',
        background: '#1f1d2b',
        calendarBackground: '#404B69',
        menuItemBackground: '#252836',
        fillIconColor: undefined as ThemeParam,
        subItemColor: '#889898',
        lineColor: '#718ebf'
    },
    input: {
        background: '#2D303E'
    }
};

export default css`
    :root {
        ${mainBackgroundColor.name}: rgb(37, 40, 54);
    }
`;
