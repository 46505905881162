import { OrganizationId } from 'api/organizations/organizations.types';
import { postWithToast, get, put } from '../../utils/fetch';
import * as Types from './committies.types';

export const createCommittees = (props: Types.CreateCommitteeFormData) =>
    postWithToast('Committee/CreateCommittee', props);

export const getOrganizationCommitties = (
    organizationId: OrganizationId = 'current'
) =>
    get<Types.CommitteesInfo[]>(
        `Committee/GetCommittiesByOrgId/${organizationId}`
    );

export const getCommitteeById = (id: string) =>
    get<Types.CommitteeFullInfo>(`Committee/${id}`);

export const updateCommittee = (props: Types.CreateCommitteeFormData) =>
    put('Committee/UpdateCommittee', props);
