export type ItemsEntity<T> = {
    items: T[];
};

export type PaginationResponse<T> = ItemsEntity<T> & {
    pageNumber: number;
    pageSize: number;
    totalCount: number;
};

export function isPaginationResponse<T>(
    response: PaginationResponse<T> | T[]
): response is PaginationResponse<T> {
    return typeof response === 'object' && 'items' in response;
}
