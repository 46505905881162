import { ChangeEvent, useCallback, useMemo, useRef, useState } from 'react';

import { Field, FieldInputProps } from 'react-final-form';
import * as S from './Select.styled';
import { SelectFormFieldProps, SelectProps } from './Select.types';
import { useClickOutside } from '../../hooks/useClickOutside';
import { useSetFalse } from '../../hooks/booleans';
import { useValidators } from '../../hooks/useValidators';
import { Dropdown, DropdownMultiple } from './Dropdown';

export function Select<OptionValue>({
    input,
    meta,
    onChange,
    value: fieldValue,
    options,
    placeholder,
    label,
    multiple
}: SelectProps<OptionValue>) {
    const [isOpen, setIsOpen] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    const close = useSetFalse(setIsOpen);

    const value = useMemo(
        () => input?.value ?? fieldValue,
        [input, fieldValue]
    );

    const filteredOptions = useMemo(() => {
        if (!searchValue) {
            return options;
        }

        return options.filter(
            (option) =>
                option.label
                    ?.toLowerCase()
                    .includes(searchValue.toLowerCase()) ||
                String(option.value)
                    .toLowerCase()
                    .includes(searchValue.toLowerCase())
        );
    }, [searchValue, options]);

    const inputProps = useMemo<FieldInputProps<string, HTMLElement>>(
        () => ({
            value: searchValue,
            name: input?.name || Symbol().toString(),
            onBlur:
                input?.onBlur ??
                (() => {
                    return;
                }),
            onFocus: (event) => {
                setIsOpen(true);
                input?.onFocus?.(event);
            },
            onChange: (event: ChangeEvent<HTMLInputElement>) => {
                setSearchValue(event.target.value);
            }
        }),
        [input, searchValue]
    );

    const onDropdownChange = useCallback(
        (newValue: OptionValue | OptionValue[]) => {
            if (!multiple) {
                close();
                setSearchValue('');
            }
            onChange?.(newValue as OptionValue & OptionValue[]);
            input?.onChange?.(newValue);
        },
        [onChange, close, multiple, input]
    );

    const onClickOutside = useCallback(() => {
        close();
        if (multiple && (value as OptionValue[]).length > 0) {
            setSearchValue('');
        }
    }, [close, multiple, value]);

    const dropdownRef = useRef<HTMLDivElement>(null);

    const ref = useClickOutside(onClickOutside, [dropdownRef], isOpen);

    const preparedPlaceholder = useMemo(() => {
        const getValue = () => {
            if (Array.isArray(value)) {
                return options
                    .filter((option) =>
                        value.some((val) => option.value === val)
                    )
                    .map(({ label, value }) => label || value)
                    .join(', ');
            } else {
                const option = options.find((option) => option.value === value);
                if (option) {
                    return option.label ?? String(option.value);
                }
            }
        };

        return getValue() || placeholder || 'Поиск...';
    }, [placeholder, value, options]);

    return (
        <S.Container ref={ref}>
            <S.Input
                input={inputProps}
                placeholder={preparedPlaceholder}
                meta={meta ?? {}}
                label={label}
                $isOpen={isOpen}
            />
            <S.Dropdown $isOpen={isOpen} ref={dropdownRef}>
                {multiple ? (
                    <DropdownMultiple<OptionValue>
                        options={filteredOptions}
                        value={value as OptionValue[]}
                        onChange={onDropdownChange}
                    />
                ) : (
                    <Dropdown<OptionValue>
                        options={filteredOptions}
                        value={value as OptionValue}
                        onChange={onDropdownChange}
                    />
                )}
            </S.Dropdown>
        </S.Container>
    );
}

export function SelectField<FormData, OptionValue = unknown>({
    name,
    validators: rawValidators,
    multiple,
    ...selectProps
}: SelectFormFieldProps<FormData, OptionValue>) {
    const validators = useValidators(rawValidators, { ...selectProps });

    if (multiple) {
        return (
            <Field<OptionValue[]>
                validate={validators}
                name={name}
                type="select"
                multiple
                render={(props) => (
                    <Select<OptionValue> multiple {...props} {...selectProps} />
                )}
            />
        );
    }

    return (
        <Field<OptionValue>
            validate={validators}
            name={name}
            type="select"
            render={(props) => (
                <Select<OptionValue> {...props} {...selectProps} />
            )}
        />
    );
}
