import styled from 'styled-components';

export const TitleCell = styled.div`
    text-align: left;
    margin-right: auto;
`;
export const CountContainer = styled.div`
    margin-top: 10px;
    color: #718ebf;
`;
