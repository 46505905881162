import React from 'react';
import { useAuth } from '../../context/Auth';
import withContainer from '../../components/Container/withContainer';
import { Headline } from '../../ui/Styled/Styled';

const Profile = () => {
    const auth = useAuth();

    if (!auth) {
        return <div>skeleton</div>;
    }

    return <Headline>Оператор</Headline>;
};

export default withContainer(Profile);
