import { OrganizationId } from 'api/organizations/organizations.types';
import { get } from '../../utils/fetch';
import * as Types from './disciplines.types';
import { ItemsEntity } from 'typings/pagination';

export const getOrganizationDisciplines = async (
    organizationId: OrganizationId = 2
) =>
    (
        await get<ItemsEntity<Types.DisciplineInfo>>(
            `Discipline/GetDisciplineListByOrgId/${organizationId}`
        )
    )?.items ?? [];
