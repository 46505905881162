import { FC, useContext, useState } from 'react';
import * as S from './Sidebar.styled';
import { RouterPaths } from 'helpers/router-paths';
import Menu from 'ui/Menu/Menu';
import { Theme, ThemeContext } from 'context/Theme/Theme.context';
import { ReactComponent as Sun } from './assets/sun.svg';
import { ReactComponent as Moon } from './assets/moon.svg';
import { useSetFalse, useSetTrue } from 'hooks/booleans';
import { ReactComponent as Logo } from './assets/logo.svg';
import { RelativeContainer } from 'ui/Styled/Styled';
import Calendar from 'react-calendar';
import { useClickOutside } from 'hooks/useClickOutside';
import { SidebarProps } from './Sidebar.types';

const THEME_NAMES: Record<Theme, { icon: React.ReactNode; label: string }> = {
    light: { icon: <Sun />, label: 'Светлая' },
    dark: { icon: <Moon />, label: 'Темная' }
};

const CalendarButton = () => {
    const [isOpen, setIsOpen] = useState(false);

    const open = useSetTrue(setIsOpen, !isOpen);
    const close = useSetFalse(setIsOpen);

    const ref = useClickOutside(close, [], isOpen);

    return (
        <RelativeContainer>
            <S.CalendarButton onClick={open} />
            {isOpen && (
                <S.CalendarContainer ref={ref}>
                    <Calendar
                        formatMonthYear={(locale, date) =>
                            date.toLocaleString(locale, { month: 'long' })
                        }
                    />
                </S.CalendarContainer>
            )}
        </RelativeContainer>
    );
};

export const Sidebar: FC<SidebarProps> = ({ ...menuProps }) => {
    const { setTheme, theme } = useContext(ThemeContext);

    return (
        <S.Sidebar>
            <S.BrandContainer>
                <S.MainLink to={RouterPaths.Home}>
                    <Logo />
                </S.MainLink>
                <CalendarButton />
            </S.BrandContainer>
            <S.MenuContainer>
                <S.MenuLabel>Меню</S.MenuLabel>
                <Menu {...menuProps} />
            </S.MenuContainer>
            <S.ThemeChoiceContainer>
                <S.ThemeChoicesRow>
                    {Object.entries(THEME_NAMES).map(
                        ([key, { icon, label }]) => (
                            <S.ThemeChoice
                                $active={theme === key}
                                onClick={() => {
                                    if (theme === key) {
                                        return;
                                    }

                                    setTheme(key as Theme);
                                }}
                                key={key}
                            >
                                <S.ThemeIcon>{icon}</S.ThemeIcon>
                                {label}
                            </S.ThemeChoice>
                        )
                    )}
                </S.ThemeChoicesRow>
            </S.ThemeChoiceContainer>
        </S.Sidebar>
    );
};
