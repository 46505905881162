import { useAuth } from '../../context/Auth';
import withContainer from '../../components/Container/withContainer';
import { getAllOrganizations } from '../../api/organizations/organizations';
import { OrganizationInfo } from '../../api/organizations/organizations.types';
import { ColumnProps } from '../../ui/Table';
import { PowerTable } from 'ui/PowerTable';
import { Headline } from 'ui/Styled/Styled';

type OrganizatonRowProps = Pick<OrganizationInfo, 'title' | 'inn'>;

const ORGANIZATIONS_COLUMNS: ColumnProps<OrganizatonRowProps> = {
    title: 'Название организации',
    inn: 'ИНН'
};

const OrganizatonList = () => {
    const auth = useAuth();

    if (!auth) {
        return <div>skeleton</div>;
    }

    return (
        <>
            <Headline>Организации</Headline>
            <PowerTable
                columns={ORGANIZATIONS_COLUMNS}
                getter={getAllOrganizations}
                noRowsMsg="Организации не найдены"
            />
        </>
    );
};

export default withContainer(OrganizatonList);
