import { get, postWithToast } from '../../utils/fetch';
import * as Types from './auth.types';

export const login = (props: Types.AuthFormData) =>
    postWithToast(`User/Authorize`, props);

export const signupOrg = (props: Types.RegisterOrgFormData) =>
    postWithToast(`User/RegisterOrganizationAndOperator`, props);

export const signupUser = (props: Types.RegisterUserFormData) =>
    postWithToast(`User/RegisterStudent`, props);

export const checkAuth = () => get(`User/CheckAuth`);
