import { useCallback, useEffect, useState } from 'react';
import { useAuth } from '../../context/Auth';
import withContainer from '../../components/Container/withContainer';
import { Headline, Title } from '../../ui/Styled/Styled';
import FormContainer from '../../ui/FormContainer/FormContainer';
import FormField from '../../ui/FormField/FormField';
import { AssignCourseFormData } from '../../api/courses/courses.types';
import { Form, useForm, useFormState } from 'react-final-form';
import {
    assignCourse,
    getCourseDateEndLearning,
    getCourses
} from '../../api/courses/courses';
import Button from '../../ui/Button/Button';
import { SelectField, Select as RawSelect } from '../../ui/Select';
import { useEffectOnce } from '../../hooks/useEffectOnce';
import { OptionProps } from '../../ui/Select/Select.types';
import { makeCreateFormFunc } from 'utils/api';
import { getAllOrganizations } from 'api/organizations/organizations';
import { useCurrentOrganization } from 'context/User';
import { getStudents } from 'api/students/students';
import { FormApi } from 'final-form';
import { isoDateWithoutTime } from 'utils/date';
import { generateMoodleReq, getMoodleRequisites } from 'api/moodle/moodle';
import InputInformation from 'ui/Input/InputInformation';
import { MoodleRequisites } from 'api/moodle/moodle.types';
import { Field as FieldFormWrapper } from 'react-final-form';
import ButtonContainer from 'ui/Button/ButtonContainer';

const Field = FormField<AssignCourseFormData>;
const Select = SelectField<AssignCourseFormData>;

const handleFormSubmit = makeCreateFormFunc(assignCourse, 'Курс назначен!');

const EndDateField = () => {
    const form = useForm<AssignCourseFormData>();

    const {
        values: { dateStartLearning, courseId }
    } = useFormState<AssignCourseFormData>();

    useEffect(() => {
        const fetchDate = async () => {
            if (dateStartLearning && courseId) {
                form.change(
                    'dateEndLearning',
                    isoDateWithoutTime(
                        await getCourseDateEndLearning(
                            courseId,
                            dateStartLearning
                        )
                    )
                );
            }
        };

        fetchDate();
    }, [form, dateStartLearning, courseId]);

    return (
        <Field
            name="dateEndLearning"
            label="Дата окончания обучения"
            type="date"
            required
        />
    );
};

const AssignCoursePage = () => {
    const auth = useAuth();

    const currentOrganizationId = useCurrentOrganization();

    const [selectedOrganizationId, setSelectedOrganizationId] =
        useState<number>();
    const [isGenerationActive, setIsGenerationActive] = useState(false)
    const [selectedStudent, setSelectedStudent] = useState<any>();
    const [reqDataMoodleUser, setReqDataMoodleUser] = useState<MoodleRequisites | null>();

    const generationReqMoodle = async () => {
        setIsGenerationActive(false);
        let reqDataUser
        if(selectedStudent){
            reqDataUser = await getMoodleRequisites(selectedStudent)
            if(!reqDataUser.login && !reqDataUser.password){
                reqDataUser = await generateMoodleReq(selectedStudent)
            }
        }
        setReqDataMoodleUser(reqDataUser)
    }

    const handleInputChange = useCallback(
        (value: any) => {
            setSelectedStudent(value);
            setIsGenerationActive(true);
            setReqDataMoodleUser(null)
        },
        []
    );

    const selectOrganization = useCallback(
        (form: FormApi<AssignCourseFormData>, value: number) => {
            setSelectedOrganizationId(value);
            form.change('courseId', undefined);
            form.change('studentId', undefined);
            setIsGenerationActive(false);
        },
        []
    );

    useEffect(
        () => setSelectedOrganizationId(currentOrganizationId),
        [currentOrganizationId]
    );

    const [organizationsOptions, setOrganizationOptions] = useState<
        OptionProps<number>[]
    >([]);

    useEffectOnce(async () => {
        setOrganizationOptions(
            (await getAllOrganizations()).items.map(({ title, id }) => ({
                label: title,
                value: id
            }))
        );
    });

    const [studentsOptions, setStudentsOptions] = useState<OptionProps[]>([]);
    const [coursesOptions, setCoursesOptions] = useState<OptionProps[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            setStudentsOptions(
                (
                    await getStudents(undefined, selectedOrganizationId)
                ).items.map(({ fullName, id }) => ({
                    label: fullName ? fullName : "",
                    value: id
                }))
            );
            setCoursesOptions(
                (await getCourses(undefined, selectedOrganizationId)).items.map(
                    ({ title, id }) => ({
                        label: title,
                        value: id
                    })
                )
            );
        };
        fetchData();
    }, [selectedOrganizationId]);
    const validate = (values: AssignCourseFormData) => {
        const errors: any = {};
        if (!values.studentId) {
            errors.studentId = 'Поле "Студент" обязательно для заполнения';
        }
        
        // Добавьте здесь другие проверки для других полей, если нужно
        return errors;
    };
    
    if (!auth) {
        return <div>skeleton</div>;
    }
    return (
        <>
            <Headline>Назначить курс</Headline>
            <Form<AssignCourseFormData>
                initialValues={{
                    dateStartLearning: isoDateWithoutTime(
                        new Date().toISOString()
                    )
                }}
                onSubmit={ handleFormSubmit }
                validate={ validate }
            >
                { ({ handleSubmit, submitting, form }) => (
                    <FormContainer onSubmit={ handleSubmit }>
                        <RawSelect
                            label="Организация"
                            options={ organizationsOptions }
                            required
                            onChange={ (value) =>
                                selectOrganization(form, value)
                            }
                            value={ selectedOrganizationId }
                            placeholder="Поиск..."
                        />
                        <FieldFormWrapper name="studentId" required initialValue={ selectedStudent }>
                            { ({ input, meta }) => (
                                <div>
                                    <RawSelect
                                        { ...input }
                                        options={ studentsOptions }
                                        label="Студент"
                                        required
                                        placeholder="Выберите студента"
                                        onChange={ handleInputChange }
                                        // value={ selectedStudent }
                                    />
                                    { meta.touched && meta.error && <span>{ meta.error }</span> }
                                </div>
                            ) }
                        </FieldFormWrapper>

                        <Select
                            name="courseId"
                            options={ coursesOptions }
                            label="Курс"
                            required
                        />

                        <Field
                            name="dateStartLearning"
                            label="Дата начала обучения"
                            type="date"
                            required
                        />

                        <EndDateField />
                        <div>
                            <Title>
                                Данные для Moodle:
                            </Title>
                            <Button
                                disabled={ !isGenerationActive }
                                onClick={ generationReqMoodle }
                            >
                                Сгенерировать
                                
                            </Button>
                        </div>
                        { !reqDataMoodleUser && <div style={{ color: 'red' }}>Получити доступ к мудлу</div> }
                        <FieldFormWrapper name="moodleLogin" required>
                            { ({ input, meta }) => (
                                <div>
                                    <InputInformation
                                        { ...input }
                                        label='Логин'
                                        value={ reqDataMoodleUser?.login }
                                    />
                                    { meta.touched && meta.error && <span>{ meta.error }</span> }
                                </div>
                            ) }
                        </FieldFormWrapper>
                        <InputInformation
                            label='Пароль'
                            value={ reqDataMoodleUser?.password }

                        />
                        <ButtonContainer>
                            <Button type="submit" disabled={ submitting }>
                                Создать
                            </Button>
                        </ButtonContainer>
                    </FormContainer>
                ) }
            </Form>
        </>
    );
};

export default withContainer(AssignCoursePage);
