import { getCommitteeById, updateCommittee } from 'api/committies/committies';
import {
    CommitteeFullInfo,
    CommitteeMemberData,
    CreateCommitteeFormData
} from 'api/committies/committies.types';
import withContainer from 'components/Container/withContainer';
import { useAuth } from 'context/Auth';
import { RouterPaths } from 'helpers/router-paths';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Headline } from 'ui/Styled/Styled';
import Skeleton from 'components/Skeleton/Skeleton';
import Button from 'ui/Button/Button';
import ButtonContainer from 'ui/Button/ButtonContainer';
import FormContainer from 'ui/FormContainer/FormContainer';
import RawFormField from 'ui/FormField/FormField';
import { Form } from 'react-final-form';
import { AddComissionMember } from 'components/AddComissionMember';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { makeCreateFormFunc } from 'utils/api';

const FormField = RawFormField<CreateCommitteeFormData>;
import { FormApi } from 'final-form';

const ComissionUpdatePage = () => {
    const auth = useAuth();
    const [comission, setComission] = useState<CommitteeFullInfo>();
    const { id } = useParams();
    const [hasFetched, setHasFetched] = useState(false);

    useEffect(() => {
        if (!id || hasFetched) {
            return;
        }

        setHasFetched(true);

        getCommitteeById(id)
            .then(setComission)
            .catch((error) => {
                console.error('Ошибка при получении комиссии:', error);
            });
    }, [id, hasFetched]);

    if (!comission) {
        return <Skeleton />;
    }

    const handleFormSubmit = (values: CreateCommitteeFormData & { id: string }, form: FormApi<CreateCommitteeFormData>) => {
        const dataToSend = {
            id: values.id,
            title: values.title,
            members: [] as CommitteeMemberData[],
        } as CreateCommitteeFormData;

        // Вызов makeCreateFormFunc с обновлением состояния
        return makeCreateFormFunc(updateCommittee, 'Комиссия изменена!')(dataToSend, form).then(() => {
            setComission((prevComission) => prevComission ? {
                ...prevComission,
                title: values.title,  // Обновляем название
            } : prevComission);
            
        });
    };

    return (
        <>
            <Headline>Редактировать { comission.title }</Headline>

            <Form
                onSubmit={ (values, form) => handleFormSubmit({ ...values, id }, form) }
                initialValues={{ id, title: comission.title, members: comission.members }} // Оставляем members для предзаполнения
                mutators={{ ...arrayMutators }}
            >
                { ({ handleSubmit, submitting }) => (
                    <FormContainer onSubmit={ handleSubmit }>
                        <FormField
                            name="title"
                            label="Название комиссии"
                            required
                        />
                        <Headline>Участники комиссии</Headline>
                        <FieldArray
                            render={ AddComissionMember }
                            name="members"
                        />
                        <ButtonContainer>                        
                            <Button type="submit" disabled={ submitting }>
                                Сохранить
                            </Button>
                        </ButtonContainer>

                    </FormContainer>
                ) }
            </Form>
        </>
    );
};

export default withContainer(ComissionUpdatePage, {
    parentPath: RouterPaths.FindComission,
    additionalItem: { path: '', text: 'Просмотр комиссии' }
});
