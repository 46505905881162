import React, { FC } from 'react';
import { useLogined } from '../../context/Auth';
import { anonymousMenu, loginedMenu } from './constants';
import MenuItem from './Menuitem/MenuItem';
import { SContainer } from './Menu.styled';
import { useLocation } from 'react-router-dom';
import { ContainerMenuProps } from './Menu.types';

const Menu: FC<ContainerMenuProps> = ({ parentPath, additionalItem }) => {
    const logined = useLogined();

    const { items } = logined ? loginedMenu : anonymousMenu;

    const { pathname } = useLocation();

    return (
        <SContainer>
            {items.map((item) => (
                <MenuItem
                    key={item.text}
                    pathname={pathname}
                    additionalItem={
                        parentPath === item.path ? additionalItem : undefined
                    }
                    {...item}
                />
            ))}
        </SContainer>
    );
};

export default Menu;
