import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { animationDuration } from 'tokens';

export const SubItemsContainer = styled.div<{ $active: boolean }>`
    display: none;

    border-left: 2px solid ${({ theme }) => theme.sidebar.lineColor};
    transition: all ${animationDuration};
    flex-flow: column;
    padding-left: 11px;
    gap: 4px;
    margin-top: 8px;

    ${({ $active }) =>
        $active &&
        css`
            display: flex;
        `}
`;

export const ChevronContainer = styled.div`
    position: absolute;
    right: 12px;
    display: flex;
    align-items: center;
    height: 100%;

    & svg,
    & path {
        transition: all ${animationDuration};

        ${({ theme }) =>
            theme.sidebar.fillIconColor &&
            css`
                fill: ${theme.sidebar.fillIconColor};
            `}
    }
`;

export const Container = styled.div<SActiveProps>`
    display: block;
    position: relative;
    background: ${({ theme }) => theme.sidebar.menuItemBackground};
    transition: all ${animationDuration};
    padding: 10px 12px;
    display: grid;
    grid-template-columns: 20px 1fr;
    gap: 12px;
    border-radius: 8px;
    cursor: pointer;

    ${({ $active }) =>
        $active &&
        css`
            & ${ChevronContainer} {
                transform: rotate(180deg);
            }
        `}

    &:hover {
        color: #718ebf;
    }
`;

export const IconContainer = styled.div<{ $baseIcon: boolean }>`
    aspect-ratio: 1;
    display: flex;
    align-items: center;

    & svg {
        width: 100%;
        max-height: 100%;

        &,
        & path {
            transition: all ${animationDuration};

            ${({ theme, $baseIcon }) =>
                theme.sidebar.fillIconColor &&
                $baseIcon &&
                css`
                    fill: ${theme.sidebar.fillIconColor};
                `}
        }
    }
`;

export const Text = styled.div`
    font-weight: 500;
    font-size: 14px;
    letter-spacing: -0.02em;
    line-height: 20px;
    color: #fff;
    font-weight: 500;
`;

export const SubItemContainer = styled(Link)<{ $active: boolean }>`
    padding: 8px 12px;
    border-radius: 8px;
    display: block;
    width: 100%;
    max-width: 172px;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -2%;
    text-decoration: none;

    transition: all ${animationDuration};

    &:hover {
        color: #fff;
    }

    ${({ $active, theme }) =>
        $active
            ? css`
                  color: #fff;

                  background: ${theme.sidebar.menuItemBackground};
              `
            : css`
                  color: ${theme.sidebar.subItemColor};
              `}
`;

export const Li = styled.li``;
