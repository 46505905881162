import React, { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';

type FormContainerProps = HTMLAttributes<HTMLFormElement>;

const StyledForm = styled.form`
    max-width: 660px;
    width: 100%;
    margin: 0 auto;
    display: grid;
    gap: 16px;
`;

const FormContainer: FC<FormContainerProps> = (props) => {
    return <StyledForm {...props} />;
};

export default FormContainer;
