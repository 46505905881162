import React, { FC, useState } from 'react';
import * as S from './Tabs.styled';

type TabsProps = {
    tabs: {
        label: string;
        content: React.ReactNode;
        anchor?: string;
    }[];
};

const Tabs: FC<TabsProps> = ({ tabs }) => {
    const [activeIndex, setActiveIndex] = useState(0);

    if (!tabs.length) {
        return null;
    }

    return (
        <>
            <S.Container>
                <S.ChoicesContainer>
                    {tabs.map(({ label }, index) => (
                        <S.Choice
                            onClick={() => setActiveIndex(index)}
                            $active={activeIndex === index}
                            key={label}
                        >
                            {label}
                        </S.Choice>
                    ))}
                </S.ChoicesContainer>
            </S.Container>
            {tabs.map(({ content }, index) => (
                <S.Content key={index} $active={index === activeIndex}>
                    {content}
                </S.Content>
            ))}
        </>
    );
};

export default Tabs;
