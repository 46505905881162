import React, {
    createContext,
    FC,
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react';
import { useEffectOnce } from '../../hooks/useEffectOnce';
import { RouterPaths } from 'helpers/router-paths';
import { useNavigate } from 'react-router-dom';
import { checkAuth } from '../../api/auth/auth';

type AuthContextProps = {
    logined?: boolean;
    refresh(): Promise<boolean>;
};

type AuthProviderProps = {
    children: React.ReactNode;
};

const AUTH_LOCAL_STORAGE_KEY = 'auth';
const AUTH_LOCAL_STORAGE_VALUE = '1';

export const AuthContext = createContext<AuthContextProps>({
    refresh: () => Promise.resolve(false)
});

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
    const [logined, setLogined] = useState<boolean | undefined>(
        localStorage.getItem(AUTH_LOCAL_STORAGE_KEY) ===
            AUTH_LOCAL_STORAGE_VALUE
            ? true
            : undefined
    );

    const check = async () => {
        try {
            await checkAuth();

            setLogined(true);
            localStorage.setItem(
                AUTH_LOCAL_STORAGE_KEY,
                AUTH_LOCAL_STORAGE_VALUE
            );
            return true;
        } catch {
            setLogined(false);
            localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);
            return false;
        }
    };

    useEffectOnce(check);

    const providerProps = useMemo(
        () => ({ logined, refresh: check }),
        [logined]
    );

    return (
        <AuthContext.Provider value={providerProps}>
            {children}
        </AuthContext.Provider>
    );
};

export const useLogined = () => {
    const { logined } = useContext(AuthContext);

    return logined;
};

export const useAuth = () => {
    const logined = useLogined();
    const navigate = useNavigate();

    useEffect(() => {
        if (logined === false) {
            navigate(RouterPaths.Auth);
        }
    }, [logined, navigate]);

    return logined || false;
};
