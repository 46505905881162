import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';
import { containerMaxWidth } from '../../tokens';

export const Wrapper = styled.div`
    position: fixed;
    max-width: ${containerMaxWidth};
    margin: 0 auto;
    width: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
`;

export const Container = styled(ToastContainer)`
    right: 40px;
    top: 40px;
    position: absolute;
`;
