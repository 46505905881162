import { OptionProps } from './Select.types';
import * as S from './Select.styled';

type DropdownProps<OptionValue, ValueType = OptionValue> = {
    options: OptionProps<OptionValue>[];
    value: ValueType;
    onChange: (value: ValueType) => void;
};

export function Dropdown<OptionValue>({
    options,
    value,
    onChange
}: DropdownProps<OptionValue>): JSX.Element {
    return (
        <>
            {options.map(({ value: optValue, label }, index) => (
                <S.DropdownItem
                    key={index}
                    $active={optValue === value}
                    onClick={() => {
                        onChange(optValue);
                    }}
                >
                    {label}
                </S.DropdownItem>
            ))}
        </>
    );
}

type DropdownMultipleProps<OptionValue> = DropdownProps<
    OptionValue,
    OptionValue[]
>;

export function DropdownMultiple<OptionValue>({
    options,
    value,
    onChange
}: DropdownMultipleProps<OptionValue>): JSX.Element {
    return (
        <>
            {options.map(({ value: optValue, label }, index) => {
                const isActive = value.includes(optValue);

                return (
                    <S.MultipleDropdownItem
                        key={index}
                        $active={isActive}
                        onClick={() => {
                            if (isActive) {
                                const newValue = [...value];

                                const index = newValue.indexOf(optValue);
                                if (index > -1) {
                                    newValue.splice(index, 1);
                                }

                                onChange(newValue);
                            } else {
                                onChange([...value, optValue]);
                            }
                        }}
                    >
                        {label || (optValue as string)}
                    </S.MultipleDropdownItem>
                );
            })}
        </>
    );
}
