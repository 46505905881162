import styled from 'styled-components';

const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    gap: 16px; // Расстояние между кнопками
    margin-bottom: 5vh;

`;

export default ButtonContainer;
