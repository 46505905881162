import { StudentInfo } from 'api/students/students.types';
import * as Types from './moodle.types';
import { get } from '../../utils/fetch';

export const getMoodleRequisites = (
    studentId: string,
    search?: string,
) => get<Types.MoodleRequisites>(`/Moodle/GetLogPas/${studentId}`, {
    search
});

export const generateMoodleReq = (
    studentId: string,
    search?: string,
) => get<Types.MoodleRequisites>(`/Moodle/GenerateLogPas/${studentId}`, {
    search
});
