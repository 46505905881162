import React from 'react';
import * as S from './ToastContainer.styled';

import 'react-toastify/dist/ReactToastify.css';

export const ToastContainer = () => (
    <S.Wrapper>
        <S.Container />
    </S.Wrapper>
);
