import styled, { css } from 'styled-components';
import { containerMaxWidth } from '../../tokens';
import { themeable } from 'themes/utils';

export const Container = styled.div`
    display: grid;
    grid-template-columns: 360px 1fr;
    gap: 20px;
    height: 100vh;
    box-sizing: border-box;
    padding: 20px;
    overflow: hidden;
    max-width: ${containerMaxWidth};
    margin: 0 auto;
`;

export const MainContainer = styled.div`
    border-radius: 16px;
    height: auto;
    position: relative;
    overflow: hidden;
`;

export const Main = styled.main<{ $disablePaddingTop?: boolean }>`
    border-radius: 16px;
    position: relative;
    background: ${themeable('mainBackgroundColor')};
    padding: 43px 24px 0;
    height: 100%;
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-flow: column;

    ${({ $disablePaddingTop }) =>
        $disablePaddingTop &&
        css`
            padding-top: 0;
        `}
`;
