import { OrganizationId } from 'api/organizations/organizations.types';
import { get, postWithToast } from '../../utils/fetch';
import * as Types from './students.types';

export const getAllStudents = (search?: string) =>
    get<Types.StudentsResponse>('Operator/GetStudentsByOrganizations', {
        search
    });

export const getStudents = (
    search?: string,
    organizationId: OrganizationId = 'current'
) =>
    get<Types.StudentsResponse>(`Organization/${organizationId}/GetStudents`, {
        search
    });

export const createStudents = (props: Types.CreateStudentFormData) =>
    postWithToast('Organization/AddOrganization', props);
