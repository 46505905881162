import React, { useMemo } from 'react';
import { useAuth } from '../../context/Auth';
import withContainer from '../../components/Container/withContainer';
import { Headline } from '../../ui/Styled/Styled';
import { PowerTable } from 'ui/PowerTable';
import { getOrganizationCommitties } from 'api/committies/committies';
import { ColumnProps, TableProps } from 'ui/Table';
import { CommitteesInfo } from 'api/committies/committies.types';
import { RouterPaths } from 'helpers/router-paths';
import { TitleCell, CountContainer } from './Comissions.styled';

const COMISSIONS_COLUMNS: ColumnProps<CommitteesInfo> = {
    title: 'Название комиссии'
};

const parseColumns: TableProps<CommitteesInfo>['parseColumns'] = {
    title: (_, row) => (
        <TitleCell>
            <div>{ row.title }</div>
            <CountContainer>
                Количество участников: { row.memberCount }
            </CountContainer>
        </TitleCell>
    )
};

const ComissionsList = () => {
    const auth = useAuth();

    if (!auth) {
        return <div>skeleton</div>;
    }

    return (
        <>
            <Headline>Список комиссий</Headline>
            <PowerTable
                getter={ getOrganizationCommitties }
                columns={ COMISSIONS_COLUMNS }
                noRowsMsg="Комиссии не найдены"
                search={ false }
                showHead={ false }
                parseColumns={ parseColumns }
                linkGetter={ RouterPaths.ComissionPage }
            />
        </>
    );
};

export default withContainer(ComissionsList);
