import { MAIN_BLOCK_ID } from 'helpers/constants';
import { FC, ReactNode } from 'react';
import { createPortal } from 'react-dom';

interface PortalProps {
    container?: Element;
    children: ReactNode;
}

export const Portal: FC<PortalProps> = ({ children, container }) => {
    return createPortal(
        children,
        container ?? document.getElementById(MAIN_BLOCK_ID) ?? document.body
    );
};
