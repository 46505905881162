import { Main } from 'components/Container/Container.styled';
import styled, { createGlobalStyle, css } from 'styled-components';
import { absoluteMixin } from 'ui/Styled/Styled';

export const Overlay = styled.div`
    ${absoluteMixin}
`;

export const Wrapper = styled.div<SOpenProps>`
    ${absoluteMixin}
    display: flex;
    align-items: center;
    justify-content: center;

    ${({ $isOpen }) =>
        !$isOpen &&
        css`
            display: none;
        `}
`;

export const Container = styled.div`
    position: relative;
    width: 100%;
    max-width: 700px;
    box-sizing: border-box;
    border-radius: 8px;
    background: #1f1d2b;
    padding: 20px;
`;

export const MainBlurStyle = createGlobalStyle`
    ${Main} {
        filter: blur(3px);
    }
`;
