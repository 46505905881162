import { useAuth } from '../../context/Auth';
import withContainer from '../../components/Container/withContainer';
import { getCourses } from '../../api/courses/courses';
import { Headline } from '../../ui/Styled/Styled';
import { CourseInfo } from '../../api/courses/courses.types';
import { ColumnProps } from '../../ui/Table';
import { PowerTable } from 'ui/PowerTable';

const COURSES_COLUMNS: ColumnProps<CourseInfo> = {
    title: 'Название курса',
    assignedQualification: 'Присваиваемая квалификация',
    hoursCount: 'Объём часов',
    issuedDocuments: 'Выдаваемый документ',
    committeeId: 'Комиссия',
    validityPeriodDocument: 'Срок действия',
    price: 'Стоимость'
};

const CoursesList = () => {
    const auth = useAuth();

    if (!auth) {
        return <div>skeleton</div>;
    }

    return (
        <>
            <Headline>Список курсов</Headline>

            <PowerTable
                columns={COURSES_COLUMNS}
                translateColumns={{
                    assignedQualification: 'qualificationTypes',
                    educationType: 'educationTypes',
                    issuedDocuments: 'issuedDocuments'
                }}
                getter={getCourses}
                noRowsMsg="Курсы не найдены"
            />
        </>
    );
};

export default withContainer(CoursesList);
