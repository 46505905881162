import React, { FC } from 'react';
import styled from 'styled-components';

type ButtonType = 'button' | 'submit' | 'reset';

interface ButtonProps {
    type?: ButtonType;
    disabled?: boolean;
    className?: string;
    children: React.ReactNode;
    onClick?(): void;
}

const StyledButton = styled.button`
    /* Кнопка */
    width: 190px;
    margin-top: 24px;
    height: 50px;
    border-radius: 8px;
    background-color: #718ebf;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    border-width: 0;
    cursor: pointer;
    
    

    &:not(:disabled):hover {
        background-color: #60779e;
    }

    &:not(:disabled):active {
        background-color: #495b79;
    }
`;

const Button: FC<ButtonProps> = ({
    type = 'button',
    disabled,
    children,
    onClick
}) => {
    const buttonProps = { type, disabled, onClick };

    return <StyledButton {...buttonProps}>{children}</StyledButton>;
};

export default Button;
