export const API_URL = `${process.env.API_URL || 'https://kursoved.pro/api'}`;

export enum ResponseCodes {
    Error = 'Error',
    Unauthorized = 'Unauthorized',
    Ok = 'Ok',
    AuthFailed = 'AuthException',
    Invalid = 'Invalid',
    EmailAlreadyUse = 'EmailAlreadyUse'
}

export const MAIN_BLOCK_ID = 'main';
