import { RuleSet } from 'styled-components';
import { ThemeValues } from './themes.types';

type ThemeProps = { theme: ThemeValues };

/**
 * функция возвращает значение параметры темы из dark.ts (darkTheme)
 **/
export const themeable =
    (param: keyof ThemeValues) =>
    ({ theme }: ThemeProps) =>
        theme[param];

/**
 * {@link import('./main.ts') link}
 */

export const forDarkTheme =
    (content: RuleSet<object>) =>
    ({ theme }: ThemeProps) =>
        theme.name === 'dark' && content;

export const forLightTheme =
    (content: RuleSet<object>) =>
    ({ theme }: ThemeProps) =>
        theme.name === 'light' && content;
