/* eslint-disable @typescript-eslint/no-explicit-any */
import { toast } from 'react-toastify';
import { API_URL, ResponseCodes } from '../helpers/constants';
import { getMessage } from '../messages';
import { FetchResult, FetchError, FormErrors } from '../typings/fetch';
import { lowerCaseFirst } from './strings';

export type FetchCallback<
    ReturnModel = any,
    RequestModel = any,
    ErrorReturn = any
> = (
    onSuccess?: (response: FetchResult<ReturnModel>) => any,

    // TODO: Починить распознование типа в зависимости от возвращаемого типа переданного колбека. Сейчас он почему-то не распознается автоматически
    onError?: (response: FetchError<RequestModel>) => ErrorReturn
) => Promise<ErrorReturn>;

export function mapToFormErrors<RequestModel = any>(
    res: FetchError<RequestModel>
): undefined | FormErrors<RequestModel> {
    return (
        res.fields &&
        (Object.entries(res.fields).reduce(
            (current, [field, value]) => ({
                ...current,
                [lowerCaseFirst(field)]: value
            }),
            {}
        ) as FormErrors<RequestModel>)
    );
}

export function fetchAPI<
    ReturnModel = unknown,
    RequestModel = unknown,
    ErrorReturn = any
>(
    input: string,
    init?: RequestInit,
    showMessage = true
): FetchCallback<ReturnModel, RequestModel, ErrorReturn> {
    return (onSuccess, onError) => {
        const throwError = (res: FetchError<RequestModel>) => {
            if (res.msg) {
                console.error(res.msg);
            }

            if (showMessage) {
                toast.error(res.msg || getMessage(res.code));
            }

            return onError?.(res) ?? mapToFormErrors(res);
        };

        return fetch(`${API_URL}/${input}`, {
            credentials: 'include',
            ...(init || {}),
            headers: {
                'Content-type': 'application/json',
                Accept: 'text/plain, application/json',
                ...(init?.headers ?? {})
            }
        })
            .then(async (res) => {
                const response = res.headers
                    .get('Content-type')
                    ?.includes('application/json')
                    ? ((await res.json()) as FetchResult<ReturnModel>)
                    : null;

                if (!res.ok) {
                    return throwError(
                        (response as FetchError<RequestModel>) ?? {
                            code: ResponseCodes.Error,
                            ok: false
                        }
                    );
                }

                return (
                    onSuccess?.(
                        response ?? { ok: true, code: ResponseCodes.Ok }
                    ) || undefined
                );
            })
            .catch((e) => {
                return throwError({
                    code: ResponseCodes.Error,
                    ok: false,
                    msg: e
                });
            });
    };
}

export function post<ErrorReturn = any, ReturnModel = any, RequestModel = any>(
    input: string,
    body: RequestModel,
    init?: Omit<RequestInit, 'method'>,
    showMessage?: boolean
) {
    return fetchAPI<ReturnModel, RequestModel, ErrorReturn>(
        input,
        {
            body: JSON.stringify(body),
            method: 'POST',
            ...(init || {})
        },
        showMessage
    );
}

export function postWithToast<
    ErrorReturn = any,
    ReturnModel = any,
    RequestModel = any
>(input: string, body: RequestModel, init?: Omit<RequestInit, 'method'>) {
    return post<ErrorReturn, ReturnModel, RequestModel>(
        input,
        body,
        init,
        true
    );
}

export function get<ReturnModel = string>(
    input: string,
    params?: Record<string, string | number | undefined>,
    init?: RequestInit
) {
    const filteredParams =
        params &&
        Object.entries(params).reduce<Record<string, string>>(
            (prev, [key, value]) => ({
                ...prev,
                ...(value ? { [key]: value.toString() } : {})
            }),
            {}
        );

    const paramsString =
        filteredParams && new URLSearchParams(filteredParams).toString();

    return new Promise<ReturnModel>((resolve, reject) => {
        fetchAPI<ReturnModel>(
            `${input}${paramsString ? `?${paramsString}` : ''}`,
            init,
            false
        )(
            (response) => {
                resolve(response.data as ReturnModel);
            },
            (err) => {
                reject(err);
            }
        );
    });
}

export function put<ErrorReturn = any, ReturnModel = any, RequestModel = any>(
    input: string,
    body: RequestModel,
    init?: Omit<RequestInit, 'method'>,
    showMessage?: boolean
) {
    return fetchAPI<ReturnModel, RequestModel, ErrorReturn>(
        input,
        {
            body: JSON.stringify(body),
            method: 'PUT', // Указываем метод PUT
            ...(init || {})
        },
        showMessage
    );
}

export function putWithToast<
    ErrorReturn = any,
    ReturnModel = any,
    RequestModel = any
>(input: string, body: RequestModel, init?: Omit<RequestInit, 'method'>) {
    return put<ErrorReturn, ReturnModel, RequestModel>(
        input,
        body,
        init,
        true // Показывать уведомления
    );
}