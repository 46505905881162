/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from 'react';
import Container, { ContainerProps } from './Container';

const withContainer =
    (Page: FC<any>, options?: Omit<ContainerProps, 'children'>) =>
    // eslint-disable-next-line react/display-name
    (props: any) => (
        <Container {...(options ?? {})}>
            <Page {...props} />
        </Container>
    );

export default withContainer;
