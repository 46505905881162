import React from 'react';

import { GlobalStyle } from './GlobalStyles';

import './App.css';
import { AuthProvider } from './context/Auth';
import { RouterProvider } from 'react-router-dom';
import router from './router';
import { ToastContainer } from './components/ToastContainer/ToastContainer';
import { UserProvider } from './context/User';
import { ThemeProvider } from 'context/Theme/Theme.context';
function App() {
    return (
        <AuthProvider>
            <UserProvider>
                <ThemeProvider>
                    <GlobalStyle />
                    <RouterProvider router={router} />
                    <ToastContainer />
                </ThemeProvider>
            </UserProvider>
        </AuthProvider>
    );
}

export default App;
