/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useMemo } from 'react';
import * as S from './Table.styled';
import { getTranslated, TranslateKey, Translates } from 'messages';
import { makeArray } from 'utils/arrays';
import { Link } from 'react-router-dom';
import Skeleton from 'components/Skeleton/Skeleton';

type RowRawProps = { onClick?(): void; link?: string };

export type RowProps<T> = T & RowRawProps;
export type ColumnProps<T> = Partial<Record<keyof T, string>>;

type RowFormattedProps<Columns> = RowRawProps &
    Record<keyof Columns, React.ReactNode>;

export type TableProps<
    T,
    Columns extends ColumnProps<T> = ColumnProps<T>,
    IdColumn extends keyof T = keyof T
> = {
    columns: Columns;
    rows: RowProps<T>[] | undefined;
    showHead?: boolean;
    loading?: boolean;
    parseColumns?: Partial<
        Record<
            keyof Columns,
            (value: unknown, row: T, rawValue: unknown) => React.ReactNode
        >
    >;
    translateColumns?: Partial<Record<keyof Columns, TranslateKey>>;
    noRowsMsg?: React.ReactNode;
    onRowClick?(row: T, index: number): void;
    idColumn?: IdColumn;
    onRowSelect?(selectedIds: T[IdColumn][], currentId: T[IdColumn]): void;

    linkGetter?: (id: T[IdColumn] | undefined, row: RowProps<T>) => string;
};

export function Table<
    T extends Record<string, unknown>,
    Columns extends ColumnProps<T> = ColumnProps<T>
>({
    columns,
    rows: rawRows,
    showHead = true,
    parseColumns,
    translateColumns,
    noRowsMsg,
    loading,
    idColumn = 'id',
    linkGetter
}: TableProps<T, Columns>): JSX.Element {
    const rows = useMemo(
        () =>
            rawRows?.map((row) => {
                const { link, ...formattedRow } = Object.entries(row).reduce<
                    RowFormattedProps<Columns>
                >((prev, [rawKey, value]) => {
                    const key = rawKey as keyof Columns;

                    const formattedValue = translateColumns?.[key]
                        ? makeArray(value)
                              .map((val) =>
                                  getTranslated(
                                      translateColumns[key] as TranslateKey,
                                      val as keyof Translates[TranslateKey]
                                  )
                              )
                              .join(', ')
                        : value;

                    return {
                        ...prev,
                        [key]:
                            parseColumns?.[key as keyof Columns]?.(
                                formattedValue,
                                row,
                                value
                            ) || formattedValue
                    };
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                }, {} as any);

                return {
                    ...formattedRow,
                    link: link || linkGetter?.(row[idColumn], row)
                };
            }),
        [rawRows, linkGetter, idColumn, translateColumns, parseColumns]
    );

    const rowsContent = useMemo(() => {
        return (
            rows?.map((fullRow, index) => {
                const { link, onClick, ...row } = fullRow;

                const content = (
                    <S.Row onClick={onClick}>
                        {Object.keys(columns).map((key: any) => (
                            <S.Cell data-key={key} key={key}>
                                {(row as any)[key]}
                            </S.Cell>
                        ))}
                    </S.Row>
                );

                if (link) {
                    return (
                        <Link key={index} to={link}>
                            {content}
                        </Link>
                    );
                }

                return <React.Fragment key={index}>{content}</React.Fragment>;
            }) ?? <></>
        );
    }, [rows, columns]);

    return (
        <>
            <S.Contaner>
                {showHead && (
                    <S.Head>
                        <S.HeadRow>
                            {Object.entries(columns).map(([key, value]) => (
                                <S.HeadingCell key={key}>
                                    {value as string}
                                </S.HeadingCell>
                            ))}
                        </S.HeadRow>
                    </S.Head>
                )}
                {loading || !rows ? (
                    <Skeleton />
                ) : rows.length > 0 ? (
                    <S.Body>{rowsContent}</S.Body>
                ) : (
                    noRowsMsg
                )}
            </S.Contaner>
        </>
    );
}
