import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
    isPaginationResponse,
    PaginationResponse
} from '../../typings/pagination';
import { Table, TableProps } from '../Table';
import * as S from './PowerTable.styled';

type PowerTableProps<RowData extends Record<string, unknown>> = Pick<
    TableProps<RowData>,
    | 'columns'
    | 'showHead'
    | 'parseColumns'
    | 'translateColumns'
    | 'noRowsMsg'
    | 'linkGetter'
    | 'idColumn'
> &
    (
        | {
              search?: true;
              getter: (
                  search?: string
              ) => Promise<PaginationResponse<RowData> | RowData[]>;
          }
        | {
              search?: false;
              getter: () => Promise<PaginationResponse<RowData> | RowData[]>;
          }
    );

export function PowerTable<RowData extends Record<string, unknown>>({
    columns,
    getter,
    search = true,
    ...tableProps
}: PowerTableProps<RowData>): JSX.Element {
    const searchRef = useRef('');
    const lastSearchedRef = useRef('');
    const [response, setResponse] = useState<
        PaginationResponse<RowData> | RowData[]
    >();
    const [loading, setLoading] = useState(true);
    const timer = useRef<NodeJS.Timeout>();

    const loadRows = useCallback(async () => {
        const rows = await getter(...(search ? [searchRef.current] : []));
        setResponse(rows);
        lastSearchedRef.current = searchRef.current;
        setLoading(false);
    }, [getter, search]);

    const refreshResults = useCallback(
        async (force = false) => {
            if (
                force ||
                (!loading && lastSearchedRef.current !== searchRef.current)
            ) {
                setLoading(true);
                loadRows();
            }
        },
        [loadRows, loading]
    );

    useEffect(() => {
        loadRows();
    }, [loadRows]);

    const changeSearchValue = useCallback(
        async (e: React.ChangeEvent<HTMLInputElement>) => {
            searchRef.current = e.target.value;

            if (timer.current) {
                clearTimeout(timer.current);
            }

            timer.current = setTimeout(refreshResults, 1000);

            return () => {
                clearTimeout(timer.current);
            };
        },
        [refreshResults]
    );

    const onSearchKeyDown = useCallback(
        ({ key }: React.KeyboardEvent<HTMLInputElement>) => {
            if (key === 'Enter') {
                refreshResults();
            }
        },
        [refreshResults]
    );

    const items = useMemo(
        () =>
            response
                ? isPaginationResponse(response)
                    ? response.items
                    : response
                : [],
        [response]
    );

    return (
        <S.Container>
            {search && (
                <S.SearchField
                    type="search"
                    placeholder="Поиск"
                    onChange={changeSearchValue}
                    onKeyDown={onSearchKeyDown}
                    readOnly={loading}
                />
            )}
            <Table
                loading={loading}
                columns={columns}
                rows={items}
                {...tableProps}
            />
        </S.Container>
    );
}
