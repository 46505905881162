import { useAuth } from '../../context/Auth';
import withContainer from '../../components/Container/withContainer';
import { Headline } from '../../ui/Styled/Styled';
import FormContainer from '../../ui/FormContainer/FormContainer';
import FormField from '../../ui/FormField/FormField';
import { CreateStudentFormData } from '../../api/students/students.types';
import { Form } from 'react-final-form';
import { createStudents } from '../../api/students/students';
import Button from '../../ui/Button/Button';
import { makeCreateFormFunc } from 'utils/api';
import ButtonContainer from 'ui/Button/ButtonContainer';

const Field = FormField<CreateStudentFormData>;

const handleFormSubmit = makeCreateFormFunc(createStudents, 'Студент создан!');

const CreateStudents = () => {
    const auth = useAuth();

    if (!auth) {
        return <div>skeleton</div>;
    }
    return (
        <>
            <Headline>Создать студента</Headline>
            <Form onSubmit={ handleFormSubmit }>
                { ({ handleSubmit, submitting }) => (
                    <FormContainer onSubmit={ handleSubmit }>
                        <Field
                            name="surname"
                            label="Фамилия"
                            placeholder="Иванов"
                            required
                        />
                        <Field
                            name="name"
                            label="Имя"
                            placeholder="Иван"
                            required
                        />
                        <Field
                            name="lastname"
                            label="Отчество"
                            placeholder="Иванович"
                            required
                        />
                        <Field
                            name="phone"
                            type="tel"
                            label="Номер телефона"
                            placeholder="+79999999999"
                            required
                        />
                        <Field
                            name="email"
                            type="email"
                            label="E-mail"
                            placeholder="example@kursoved.pro"
                            required
                        />
                        <Field
                            name="speciality"
                            required
                            label="Специальность"
                            placeholder="Специальность..."
                        />
                        <Field
                            name="professionalRank"
                            type="string"
                            required
                            label="Разряд"
                            placeholder="Текст"
                        />
                        <Field
                            name="city"
                            type="string"
                            required
                            label="Город"
                            placeholder="Введите название населенного пункта"
                        />
                        <Field
                            name="organization"
                            type="string"
                            required
                            label="Организация"
                            placeholder="Название организации"
                        />
                        <Field
                            name="avatarProfile"
                            type="string"
                            required
                            label="Фото студента"
                            placeholder=""
                            showButton={ true } // Показать кнопку
                            onButtonClick={ () => {
                                // Действие при клике
                                alert('Кнопка нажата');
                            } }
                        />
                        <Field
                            name="passportNumberAndScan"
                            type="string"
                            required
                            label="Паспорт"
                            placeholder="Номер паспорта"
                            showButton={ true } // Показать кнопку
                            onButtonClick={ () => {
                                // Действие при клике
                                alert('Кнопка нажата');
                            } }
                        />
                        <Field
                            name="snilsNumberAndScan"
                            type="string"
                            required
                            label="СНИЛС"
                            placeholder="Номер снилс"
                            showButton={ true } // Показать кнопку
                            onButtonClick={ () => {
                                // Действие при клике
                                alert('Кнопка нажата');
                            } }
                        />
                        <Field
                            name="diplomNumberAndScan"
                            type="string"
                            required
                            label="Диплом"
                            placeholder="Номер диплома"
                            showButton={ true } // Показать кнопку
                            onButtonClick={ () => {
                                // Действие при клике
                                alert('Кнопка нажата');
                            } }
                        />
                        <Field
                            name="documentOfAdvancedTraining"
                            type="string"
                            required
                            label="Документ о повышении квалификации"
                            placeholder="Номер документа"
                            showButton={ true } // Показать кнопку
                            onButtonClick={ () => {
                                // Действие при клике
                                alert('Кнопка нажата');
                            } }
                        />
                        <ButtonContainer>
                            <Button type="submit" disabled={ submitting }>
                                Создать
                            </Button>
                        </ButtonContainer>
                    </FormContainer>
                ) }
            </Form>
        </>
    );
};

export default withContainer(CreateStudents);
